import React, {useState} from 'react';
import {Button, Collapse, Image} from "antd";
import {useSelector} from "react-redux";
import {IAppState} from "../../interface/redux/IAppState";
import {Link, useParams} from "react-router-dom";
import FileService from "../../service/app/FileService";
import {IModularHouse} from "../../interface/data/IModularHouse";
import {EditOutlined} from "@ant-design/icons";
import ModularHouseForm from "../form/ModularHouseForm";
import NotFound from "../../components/NotFound";

const ModularHouseDetail: React.FC = () => {


    const [activeKeys, setActiveKeys] = useState<string[]>([])

    const {name} = useParams();
    const houses = useSelector((state: IAppState) => state.setup.modularHouses).filter(h => h.isPublic);
    const user = useSelector((state: IAppState) => state.setup.user);
    const house = houses.find(h => h.name === name || h.name.normalize('NFD').replace(/[^a-zA-Z0-9\s]/g, '').replace(/[\s]+/g,'_') === name)


    const [edit, setEdit] = useState<boolean | IModularHouse>(false);

    if (!house) {
        return <NotFound/>
    }

    return (
        <div>
            <div>
                <div
                    className="shop-container container padd-only-xs product-template-default single single-product postid-2072 woocommerce woocommerce-page woocommerce-no-js wpb-js-composer js-comp-ver-5.6 responsive">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 margin-lg-90b margin-sm-60b">
                            <div className="woocommerce-notices-wrapper"></div>
                            <div id="Lyon s terasou- nyní akce: kuchyňská linka a doprava zdarma!!!"
                                 className="post-2072 product type-product status-publish has-post-thumbnail product_cat-acssessories product_tag-casual product_tag-modern first instock virtual purchasable product-type-simple">
                                <div className="flex-item ">
                                    <Image.PreviewGroup>
                                        <div
                                            className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-3 images"
                                            data-columns="3">
                                            <div className="flex-viewport">
                                                <figure className="woocommerce-product-gallery__wrapper">
                                                    <div
                                                        className="woocommerce-product-gallery__image flex-active-slide">
                                                        <Image
                                                            preview={{mask: undefined}}
                                                            draggable="false"
                                                            className=" attachment-shop_single size-shop_single firstImg cursor-pointer"
                                                            src={FileService.buildSrcUrl(house.images[0]?.uid)}/>
                                                    </div>
                                                </figure>
                                            </div>
                                            <ol className="flex-control-nav flex-control-thumbs">
                                                {house.images.map(image => <li>
                                                    <Image
                                                        preview={{mask: undefined}}
                                                        draggable="false"
                                                        className=" attachment-full size-full cursor-pointer clicked_alessandria_1"
                                                        src={FileService.buildSrcUrl(image.uid)}/>
                                                </li>)}
                                            </ol>

                                        </div>
                                    </Image.PreviewGroup>
                                    <div className="summary entry-summary">
                                        {user &&  <div style={{position: "absolute", top: '-5px', right: '-5px', zIndex: 1}}>
                                            <Button shape={"circle"} icon={<EditOutlined/>} onClick={() => setEdit(true)}/>
                                            {edit && <ModularHouseForm house={house} onFinish={() => setEdit(false)}/>}
                                        </div>}
                                        <h2 className="product_title entry-title">{house.name}</h2><p className="price"
                                                                                                  style={{
                                                                                                      color: "rgb(160, 117, 59)",
                                                                                                      fontSize: '55px'
                                                                                                  }}>
                                        <span
                                            className="woocommerce-Price-amount amount">{house.price?.toLocaleString()}</span><span
                                        className="woocommerce-Price-currencySymbol">Kč</span></p><Link
                                        className="prague-services-link a-btn-2 creative anima" to="/kontakt"
                                        target="_self" style={{marginLeft: '8px', marginBottom: '10px'}}><span
                                        className="a-btn-line"></span>MÁM ZÁJEM </Link>
                                        <div className="woocommerce-product-details__short-description">
                                            {house.roof && <p>Střecha: <strong>{house.roof}</strong></p>}
                                            {house.frame && <p>Rám: <strong>{house.frame}</strong></p>}
                                            {house.heating && <p>Topné zařízení: <strong>{house.heating}</strong></p>}
                                            {house.isolation && <p>Izolace: <strong>{house.isolation}</strong></p>}
                                            {house.wiring && <p>Zapojení: <strong>{house.wiring}</strong></p>}
                                            {house.floor && <p>Podlahové krytiny: <strong>{house.floor}</strong></p>}
                                            {house.bathroomFloor &&
                                                <p>Podlahové krytiny v koupelně: <strong>{house.bathroomFloor}</strong>
                                                </p>}
                                            {house.window && <p>Okna: <strong>{house.window}</strong></p>}
                                            {house.door && <p>Dveře: <strong>{house.door}</strong></p>}
                                            {house.equipment &&
                                                <p>Vybavení domu: <strong>{house.equipment}</strong></p>}
                                            {house.outerWallDecoration &&
                                                <p>Dekorace vnější stěny: <strong>{house.outerWallDecoration}</strong>
                                                </p>}
                                            {house.innerWallDecoration && <p>Dekorace vnitřní stěny a
                                                stropu: <strong>{house.innerWallDecoration}</strong>
                                            </p>}

                                            {house.note && <div><p>Další poznámky:</p><strong
                                                dangerouslySetInnerHTML={{__html: house.note}}>
                                            </strong></div>}
                                        </div>
                                        <Collapse onChange={setActiveKeys} bordered={false} ghost={true} items={[{
                                            key: 1,
                                            showArrow: false,
                                            label: <div>
                                                <div className="woocommerce-tabs wc-tabs-wrapper">
                                                    <div
                                                        className={"tabs-item-wrapp " + (activeKeys.includes('1') ? 'active' : '')}>
                                                        <div className="description_tab tabs-title"
                                                             id="tab-title-description" aria-controls="tab-description">
                                                            <span className="tabs-mark"><span
                                                                className="tabs-mark-plus">+</span><span
                                                                className="tabs-mark-minus">-</span></span>Rozměry
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>,
                                            children: <div
                                                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                                                id="tab-description" aria-labelledby="tab-title-description ">
                                                {house.outerLength && <p>Vnější: <span
                                                    style={{color: 'rgb(126, 125, 125)'}}>{house.outerLength} x {house.outerHeight} x {house.outerWidth} m</span>
                                                </p>}
                                                {house.innerLength && <p>Vnitřní: <span
                                                    style={{color: 'rgb(126, 125, 125)'}}>{house.innerLength} x {house.innerHeight} x {house.innerWidth} m</span>
                                                </p>
                                                }
                                                <p>Výměra: <span style={{color: 'rgb(126, 125, 125)'}}>{house.area || '?'} m2</span></p>
                                            </div>
                                        }]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default ModularHouseDetail;
