import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Col, Row} from "antd";
import {Link} from "react-router-dom";
import {ISipHouse} from "../../interface/data/ISipHouse";
import {AppDispatch} from "../../redux/store";
import {IAppState} from "../../interface/redux/IAppState";
import {updateSetup} from "../../redux/slice/Setup";
import FileService from "../../service/app/FileService";
import SipHouseService from "../../service/app/SipHouseService";
import HouseCardActions from "./HouseCardActions";
import {EyeInvisibleOutlined} from "@ant-design/icons";


interface IProps {
    house: ISipHouse
    onEdit: (house: ISipHouse) => void
    handleProps?: any
}

const SipHouseCard: React.FC<IProps> = ({house, onEdit, handleProps}) => {

    const houses = useSelector((state: IAppState) => state.setup.sipHouses);
    const user = useSelector((state: IAppState) => state.setup.user);
    const dispatch = useDispatch<AppDispatch>();

    const [deleting, setDeleting] = useState<undefined | string>();

    const remove = (house: ISipHouse) => {
        setDeleting(house.id)
        SipHouseService.delete(house.id).then(() => {
            setDeleting(undefined)
            dispatch(updateSetup({sipHouses: houses.filter(h => h.id !== house.id)}));
        })
    }

    return (
        <Row className="project-grid-wrapper zoom-in flex-column">
            <Link className="project-grid-item-img-link position-relative d-flex"
                  to={"/sip/katalog/" + encodeURIComponent(house.name.normalize('NFD').replace(/[^a-zA-Z0-9\s]/g, '').replace(/[\s]+/g,'_'))}
                  style={{flexGrow: 1, display: "flex"}}>
                <img src={FileService.buildSrcUrl(house.images[0]?.uid || '')}
                     className="sip-catalog project-grid-item-img"
                     alt="LETNÝ DOM PRI PRAMENI"
                     style={{height: "auto"}}/>
                {house.isPublic === false && user && (
                    <div className="overlay z-index-1">
                        <Alert type={'info'} banner={true} icon={<EyeInvisibleOutlined/>}
                               description={'Neveřejný'}/>
                    </div>
                )}
            </Link>
            <div className="project-grid-item-content">
                <div className="position-absolute"
                     style={{top: '5px', right: '5px', zIndex: 1}}>
                    <HouseCardActions user={user} onClick={() => onEdit(house)} handleProps={handleProps}
                                      onConfirm={() => remove(house)} deleting={deleting} id={house.id}/>
                </div>
                <Row justify={"center"} align={'middle'}>
                    <Col>
                        <h4 className="project-grid-item-title">
                            <Link className="text-uppercase"
                                  to={"/sip/katalog/" + encodeURIComponent(house.name.normalize('NFD').replace(/[^a-zA-Z0-9\s]/g, '').replace(/[\s]+/g,'_'))}>
                                {house.name}
                            </Link>
                        </h4>
                        <div className="project-grid-item-category">{house.area} m2
                        </div>
                    </Col>
                </Row>
            </div>
        </Row>
    );
}

export default SipHouseCard;
