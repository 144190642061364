import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Image} from "antd";
import {Link} from "react-router-dom";
import {IRealization} from "../../interface/data/IRealization";
import {AppDispatch} from "../../redux/store";
import {IAppState} from "../../interface/redux/IAppState";
import {updateSetup} from "../../redux/slice/Setup";
import FileService from "../../service/app/FileService";
import SipHouseService from "../../service/app/SipHouseService";
import {EyeInvisibleOutlined} from "@ant-design/icons";
import HouseCardActions from "./HouseCardActions";


interface IProps {
    house: IRealization
    onEdit: (house: IRealization) => void
    handleProps?: any
}

const RealizationCard: React.FC<IProps> = ({house, onEdit, handleProps}) => {

    const houses = useSelector((state: IAppState) => state.setup.realizations);
    const user = useSelector((state: IAppState) => state.setup.user);
    const dispatch = useDispatch<AppDispatch>();

    const [deleting, setDeleting] = useState<undefined | string>();

    const remove = (house: IRealization) => {
        setDeleting(house.id)
        SipHouseService.delete(house.id).then(() => {
            setDeleting(undefined)
            dispatch(updateSetup({realizations: houses.filter(h => h.id !== house.id)}));
        })
    }

    return (

        <div className="portfolio-item-wrapp">
            <div className="portfolio-item">
                <div className="project-masonry-wrapper"><Link
                    className="project-masonry-item-img-link"
                    to={"/realizace/" + encodeURIComponent(house.name.normalize('NFD').replace(/[^a-zA-Z0-9\s]/g, '').replace(/[\s]+/g,'_'))}>
                    <div className="project-masonry-item-img">
                        <Image
                            alt={house.location}
                            className="scale"
                            src={FileService.buildSrcUrl(house.images[0]?.uid)}/>
                    </div>
                    {house.isPublic === false && user && (
                        <div className="overlay z-index-1">
                            <Alert type={'info'} banner={true} icon={<EyeInvisibleOutlined/>} description={'Neveřejný'}/>
                        </div>
                    )}
                    <HouseCardActions user={user} onClick={() => onEdit(house)} handleProps={handleProps}
                                      onConfirm={() => remove(house)} deleting={deleting} id={house.id}/>
                    <div className="project-masonry-item-content">
                        <h4 className="project-masonry-item-title">{house.name}</h4>
                        <div className="project-masonry-item-category">{house.location}</div>
                    </div>
                </Link></div>
            </div>
        </div>
    );
}

export default RealizationCard;
